import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import FS from "../components/FieldStructure.js";
import MaterialTable from "material-table";
import $ from "jquery";
import { checkStatus } from "../components/functions.js";
import Audits from '../components/Audits.js';
import userService from '../services/user.service.js';
import organizationService from '../services/organizations.js';
import AuthService from '../services/auth.service.js';

import HistoryIcon from '@mui/icons-material/History';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { ROLE_COORDINADOR, ROLE_SYSTEM_ADMIN } from '../utils/roles.js';
import SecuredComponent from '../components/SecuredComponent.js';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddUsers: false,
      editingData: [],
      organizaciones: [],
      roles: [],
      auditType: "usuarios",
      showAudit: null
    };

    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);

    this.show_PopUpUsers = this.show_PopUpUsers.bind(this);
    this.hide_PopUpUsers = this.hide_PopUpUsers.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  componentDidMount() {
    const auth = AuthService.getCurrentUser();;
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.administracion .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.usuarios").addClass("active");
    });
    const isAdmin = auth.user.role.nombre === ROLE_SYSTEM_ADMIN;
    const organizationParams = { estado: 'activos', paginacion: 999999 };
    if (!isAdmin) {
      organizationParams.organizacion = auth.user.organizacion.id;
    }
    organizationService.query(organizationParams).then(response => {
      this.setState({ organizaciones: response.data.data ?? [] });
    });

    AuthService.fetchUserRoles().then(response => {
      this.setState({ roles: response.data.data ?? [] });
    });

    let these = this;
    setTimeout(function () {
      these.setState({ show: true });
    }, 200);
  }

  showAudit(id) {
    this.setState({
      showAudit: id
    })
  }
  closeAudit() {
    this.setState({
      showAudit: null
    })
  }
  show_PopUpUsers(data) {
    const { profile } = data;
    const { nombre, email, telefono, movil } = profile ?? {};
    this.setState({ popupAddUsers: true, editingData: { ...data, nombre, email, telefono, movil } });
  }

  hide_PopUpUsers() {
    this.setState({ popupAddUsers: false });
  }

  editRow(data) {
    this.show_PopUpUsers(data);
  }

  desactivateRow(data) {
    userService.toggle(data.id).then(() => {
      data.activo = false;
      this.setState({
        alertShow: true,
        alertMessage: "El usuario ha sido desactivado con éxito",
        alertType: "success",
      });
      this.tableRef.current.onQueryChange({});
    });
  }

  activeRow(data) {
    userService.toggle(data.id).then(() => {
      data.activo = true;
      this.setState({
        alertShow: true,
        alertMessage: "El usuario ha sido activado con éxito",
        alertType: "success",
      });
      this.tableRef.current.onQueryChange({});
    });
  }

  fetchData(query) {
    return new Promise(resolve => {
      const params = { page: query.page + 1, paginacion: 30 };

      if (query.search) {
        params.busqueda = query.search;
      }
      const auth = AuthService.getCurrentUser();
      if (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN) {
        params.organizacion = auth.user.organizacion.id;
      }
      userService.getUsers(params).then(response => {
        resolve({
          data: response.data.data,
          page: response.data.meta.current_page - 1,
          totalCount: response.data.meta.total
        });
      });
    })
  }

  render() {
    const {
      editingData,
      popupAddUsers,
      organizaciones,
      roles,
    } = this.state;
    const addUserValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      // username: Yup.string().required('El campo de nombre de usuario es requerido'),
      email: Yup.string().required("El Correo es un campo requerido."),
      password: Yup.string().required("La Contraseña es un campo requerido."),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], "La contraseña no coincide.").required("La Confirmación de Contraseña es un campo requerido."),
      telefono: Yup.string().required("El Teléfono es un campo requerido."),
      organizacion: Yup.string().required("La organización es un campo requerido."),
      role: Yup.string().required("El Rol es un campo requerido.")
    });

    const auth = AuthService.getCurrentUser();
    return (
      <Container>
        {this.state.showAudit ? (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        ) : null}
        <SecuredComponent roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR]}>
          <div className="titleButtons">
            <Nav className="justify-content-end">
              <Nav.Item>
                <span className="nav-link" onClick={this.show_PopUpUsers}>
                  Crear Usuario
                </span>
              </Nav.Item>
            </Nav>
          </div>
        </SecuredComponent>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  hidden: true,
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "profile.nombre" },
                { title: "Email", field: "profile.email" },
                { title: "Rol", field: "role.nombre" },
                {
                  title: "Organización",
                  field: "organizacion.id",
                  hidden: auth.user.role.nombre !== ROLE_SYSTEM_ADMIN,
                  render: (rowData) => (organizaciones.find(elem => elem.id === rowData.organizacion.id)?.nombre)
                },
                { title: "Teléfono", field: "profile.telefono" },
                {
                  title: "Estatus", field: "activo",
                  render: rowData => (
                    checkStatus(rowData.activo)
                  )
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                paging: true,
                emptyRowsWhenPaging: false,
                pageSize: 30
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                }
              }}
              data={this.fetchData}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, rowData) => this.editRow(rowData)
                },
                rowData => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: 'Desactivar',
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: !rowData.activo
                }),
                rowData => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: 'Activar',
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo
                }),
                rowData => ({
                  icon: HistoryIcon,
                  tooltip: 'Auditoria',
                  onClick: (event, rowData) => this.showAudit(rowData.id)
                })
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          size="xl"
          centered
          show={popupAddUsers}
          onHide={this.hide_PopUpUsers}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                email: editingData.email || "",
                password: editingData.password || "",
                password_confirmation: "",
                telefono: editingData.telefono || "",
                organizacion: editingData.organizacion?.id ?? '',
                role: editingData.role?.id ?? '',
              }}
              enableReinitialize={true}
              validationSchema={addUserValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.activo = true;
                this.hide_PopUpUsers();
                if (editingData.id !== undefined) {
                  userService.update({ ...values, username: values.email, id: editingData.id }).then(() => {
                    this.setState({
                      alertShow: true,
                      alertMessage: "El usuario ha sido actualizado con éxito",
                      alertType: "success",
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                } else {
                  userService.save({ ...values, username: values.email }).then(() => {
                    this.setState({
                      alertShow: true,
                      alertMessage: "El Usuario fue creado con éxito",
                      alertType: "success",
                    });
                    this.tableRef.current.onQueryChange({});
                  });
                }
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleChange, handleSubmit, isSubmitting, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <FS label="Nombre" name="nombre" errors={errors} col={[6]}>
                      <Field type="text" name="nombre" onchange={handleChange} />
                    </FS>
                    <FS label="Email" name="email" errors={errors} col={[6]}>
                      <Field type="text" name="email" onchange={handleChange} />
                    </FS>
                    <FS label="Teléfono" name="telefono" errors={errors} col={[6]}>
                      <Field type="text" name="telefono" onchange={handleChange} />
                    </FS>
                    <FS
                      label="Contraseña"
                      name="password"
                      errors={errors}
                      col={[6]}
                    >
                      <Field
                        type="password"
                        name="password"
                        onchange={handleChange}
                      />
                    </FS>
                    <FS
                      label="Confirmación de Contraseña"
                      name="password_confirmation"
                      errors={errors}
                      col={[6]}
                    >
                      <Field
                        type="password"
                        name="password_confirmation"
                        onchange={handleChange}
                      />
                    </FS>
                    <FS
                      label="Organización"
                      name="organizacion"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="organizacion" as="select" onchange={handleChange}>
                        <option value=""> Selecciona </option>
                        {organizaciones.map((p) => (
                          <option key={p.id} value={p.id}> {p.nombre}</option>
                        ))}
                      </Field>
                    </FS>
                    <FS
                      label="Role"
                      name="role"
                      errors={errors}
                      col={[6]}
                    >
                      <Field name="role" as="select" onchange={handleChange}>
                        <option value=""> Selecciona </option>
                        {roles.map(role => (<option key={role.id} value={role.id}>{role.nombre}</option>))}
                      </Field>
                    </FS>

                    <Col lg="12">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="primary "
                      >
                        {isSubmitting ? "Guardando..." : "Guardar"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(UsersList);
