import React, { Component } from "react";
import { Button, Col, Row, Modal, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import DatePickerField from "../common/DatePicker";
import Select from 'react-select';
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import FS from "../components/FieldStructure";

import { getName } from "../components/functions";
import $ from "jquery";
import { API_URL } from "../utils/config";
import AuthService from '../services/auth.service';
import iconReport1 from '../assets/iconReport1.png';
import iconReport3 from '../assets/iconReport3.png';
import iconReport4 from '../assets/iconReport4.png';
import iconReport5 from '../assets/iconReport5.png';
import fileDownload from 'js-file-download';
import ModalRegistro from './ModalRegistro';
import ModalExportableEncuestas from './ModalExportableEncuestas.jsx';
import ModalExportableEncuestas2 from './ModalExportableEncuestas2.jsx';
import { ROLE_COORDINADOR, ROLE_DIGITADOR, ROLE_SYSTEM_ADMIN } from '../utils/roles';
import SecuredComponent from '../components/SecuredComponent';
import ModalAdministracion from './ModalAdministracion.jsx';
import ModalAvancesPorPrograma from './ModalAvancesPorPrograma.jsx';
import ModalReporte13 from './ModalReporte13';
import ModalReporteEncuestas from './ModalReporteEncuestas';
import ModalReporteEncuestas2 from './ModalReporteEncuestas2';
import ModalResultadosEncuestas from './ModalResultadosEncuestas';
import ModalReporte7 from './ModalReporte7';
import ModalReporte4 from './ModalReporte4';
import ModalAdministracion2 from './ModalAdministracion2';
import ModalBeneficiariosDeSalida from './ModalBeneficiariosDeSalida';
import ModalBeneficiariosDeSalida19 from './ModalBeneficiariosDeSalida19';
import { ModalReporte20 } from "./ModalReporte20";
import { ModalReporte4_1 } from "./ModalReporte4_1.jsx";

const API = API_URL;

const validationSchema = Yup.object().shape({
    fecha_inicio: Yup.string().required("Fecha de Creación Inicial es un campo requerido."),
    fecha_fin: Yup.string().required("Fecha de Creación Final es un campo requerido.")
});

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            popupReportView: false,
            reportId: 0,
            organizaciones: [],
            tipoActividades: [],
            actividades: [],
            reportName: "",
            options: [],
            additionalForms: [],
            programas: [],
            formTypes: [],
            programstype: [],
            fieldresponses: [],
            fieldresponsesSelected: [],
            escalaRiesgoSelected: [],
            formfields: [],
            dependence_type: "",
            forms: [],
            showModalRegistro: false,
            showModalEncuestas: false,
            selectedReportTitle: '',
            selectedReport: null,
            export: false
        };
        this.show_PopUpReportView = this.show_PopUpReportView.bind(this);
        this.hide_PopUpReportView = this.hide_PopUpReportView.bind(this);
        this.addDependence = this.addDependence.bind(this);
    }
    show_PopUpReportView(reportId, options, name, expo) {
        this.setState({ popupReportView: true, reportId: reportId, options: options, reportName: name, export: expo });
        localStorage.setItem("reportName", name);
    }
    hide_PopUpReportView(data) {

        this.setState({ popupReportView: false });
    }

    remove_question(data) {
        console.log(this.state.escalaRiesgoSelected);
        if (data.length === undefined) {
            this.setState({
                escalaRiesgoSelected: this.state.escalaRiesgoSelected.filter(function (item) {
                    return item.form_field_id !== data.form_field_id || item.answer !== data.answer || item.operator !== data.operator
                })
            });
        } else {
            var escalaRiesgoSelected = this.state.escalaRiesgoSelected;
            for (var i = data.length - 1; i >= 0; i--) {
                escalaRiesgoSelected = escalaRiesgoSelected.filter(item => item.id !== data[i].id)
            }

            this.setState({ escalaRiesgoSelected: escalaRiesgoSelected });
        }
    }
    addDependence(values, dependence_type) {
        if (values.escalariesgo_formfield === null) {
            return false;
        }
        if (values.escalariesgo_response === null) {
            return false;
        }

        let response = values.escalariesgo_response;
        let response2 = values.escalariesgo_response
        if (dependence_type === "621e56e0ef6d6654e9371c82") {
            response = values.escalariesgo_response.map(function (val) {
                return val._id;
            }).join(',')
            response2 = values.escalariesgo_response.map(function (val) {
                return val.name;
            }).join(',')
        }

        this.setState({
            escalaRiesgoSelected: [
                ...this.state.escalaRiesgoSelected,
                {
                    answer: response,
                    answerName: response2,
                    operator: values.escalariesgo_operator,
                    form_field_id: values.escalariesgo_formfield
                },

            ],
        });
        // values.escalariesgo_response = "";
        // values.escalariesgo_formfield = "";
    }


    componentDidMount() {
        const isAdmin = AuthService.isAdmin();
        const auth = AuthService.getCurrentUser();

        req.get(`${API_URL}selectores/actividades`).then(response => {
            this.setState({ actividades: response.data.data ?? [] });
        });
        req.get(`${API_URL}selectores/programas`).then(response => {
            this.setState({ programas: response.data.data ?? [] });
        });
        req.get(`${API_URL}selectores/tipos/programa`).then(response => {
            this.setState({ programstype: response.data.data ?? [] });
        });
        req.get(`${API_URL}selectores/formularios`).then(response => {
            this.setState({ forms: response.data.data ?? [] });
        });
        req.get(`${API_URL}selectores/tipos/formulario`).then((response) => {
            this.setState({ formTypes: response.data.data ?? [] });
        });
        req.get(`${API_URL}selectores/organizaciones`).then(response => {
            if (!isAdmin) {
                const filteredOrganizations = response.data.data.filter(x => x.id == auth?.user.organizacion.id);
                this.setState({ organizaciones: filteredOrganizations ?? [] });
            } else {
                this.setState({ organizaciones: response.data.data ?? [] });
            }
        });
        req.get(`${API_URL}selectores/tipos/actividad`).then(response => {
            this.setState({ tipoActividades: response.data.data ?? [] });
        });
        $(document).ready(function () {
            $(".nav-link").removeClass("active");
            $(".nav-link[href='/reportes']").addClass("active");


        });
        let these = this;
        setTimeout(function () {
            these.setState({ show: true });
        }, 200);

        req.get(`${API_URL}formularios/respuestas`, { params: { estado: 'activos', paginacion: 999999 } }).then((response) => {
            let realResponses = [];
            let responseReal = response.data.data || response.data;
            this.setState({
                fieldresponses: responseReal,
                fieldresponsesSelected: realResponses,
                isEditing: true,
            });
        });
        req.get(`${API_URL}config`, { params: { estado: 'activos', paginacion: 999999 } }).then((res) => {
            const configs = res.data.data ?? [];
            const additionalForms = configs.filter(config => config.key.includes('additionalForm_'));
            this.setState({
                isLoaded: true,
                additionalForms,
            });
        });
        req.get(`${API_URL}selectores/preguntas`).then(response => {
            this.setState({ formfields: response.data.data ?? [] });
        });
    }
    selectDependence_formfield(value, setField) {
        let these = this;
        req.get(`${API_URL}selectores/preguntas`).then((response3) => {
            Object.keys(response3.data.data).forEach(function (status, key) {
                if (response3.data.data[key]._id === value) {
                    these.setState({
                        dependence_type: response3.data.data[key].questio_type_id,
                    });
                    setField("escalariesgo_formfield", response3.data.data[key]._id);
                }
            });

        });
    }
    render() {
        const { show, popupReportView, additionalForms, reportId, organizaciones, actividades, reportName, programstype, forms,
            formfields, dependence_type, fieldresponses, escalaRiesgoSelected } = this.state;
        const auth = JSON.parse(localStorage.getItem('user'));

        const trabajoEs = {
            "6": {
                title: "Tipo de Riesgos Detectados", options: [
                    { name: "Documentación", _id: "Documentación" },
                    { name: "Jóvenes con Dependientes", _id: "Jóvenes con Dependientes" },
                    { name: "Riesgo Educación", _id: "Riesgo Educación" },
                    { name: "Trabajo / Riesgo Profesional", _id: "Trabajo / Riesgo Profesional" },
                    { name: "Víctima", _id: "Víctima" },
                    { name: "Crimen", _id: "Crimen" },
                    { name: "Uso de Sustancias", _id: "Uso de Sustancias" },
                    { name: "Infección de Transmisión Sexual Riesgo", _id: "Infección de Transmisión Sexual Riesgo" },
                    { name: "Embarazo", _id: "Embarazo" },
                    { name: "Pandilla", _id: "Pandilla" }
                ]
            },
            "8": {
                title: "Tipo de Riesgos Detectados", options: [
                    { name: "Usuario de drogas", _id: "Usuario de drogas" },
                    { name: "Trabajadores/as del sexo", _id: "Trabajadores/as del sexo" },
                    { name: "Hombres que tienen sexo con hombres", _id: "Hombres que tienen sexo con hombres" }
                ]
            },
            "14": {
                title: "Este trabajo es", options: [
                    { name: "Jóvenes con nuevo empleo", _id: "Jóvenes con nuevo empleo" },
                    { name: "Jóvenes con mejor empleo", _id: "Jóvenes con mejor empleo" },
                    { name: "Jóvenes con nuevo emprendimiento", _id: "Jóvenes con nuevo emprendimiento" },
                    { name: "Jóvenes con mejor emprendimiento", _id: "Jóvenes con mejor emprendimiento" },
                    { name: "Emprendimiento EG5-3", _id: "Emprendimiento EG5-3" },
                ]
            },
            "26": {
                title: "Tipo de Discapacidad", options: [
                    { name: "Auditiva", _id: "Auditiva" },
                    { name: "Visual", _id: "Visual" },
                    { name: "Lenguaje", _id: "Lenguaje" },
                    { name: "Física", _id: "Física" },
                ]
            },
            "28": {
                title: "Tipo de Violencia de Género", options: [
                    { name: "Abuso sexual", _id: "Abuso sexual" },
                    { name: "Abuso físico", _id: "Abuso físico" },
                    { name: "Abuso emocional", _id: "Abuso emocional" }
                ]
            },
            "30": {
                title: "Tipo de Orientación Sexual", options: [
                    { name: "Heterosexual (Me gusta el sexo opuesto al mío)", _id: "Heterosexual (Me gusta el sexo opuesto al mío)" },
                    { name: "Lesbiana/Gay (Me gusta mi mismo sexo)", _id: "Lesbiana/Gay (Me gusta mi mismo sexo)" },
                    { name: "Bisexual (Me gustan ambos sexos)", _id: "Bisexual (Me gustan ambos sexos)" }
                ]
            }
        }
        return (
            <Container>
                <CSSTransition
                    unmountOnExit
                    in={show}
                    timeout={200}
                    classNames="transitionPage"
                >
                    <Row>
                        <Col lg="6">
                            <div className="mainBox reportsBox marginBottom80">
                                <img alt="icon report 1" src={iconReport1} />
                                <h4>Administración</h4>
                                <Row>
                                    <Col lg="12">
                                        <ul>
                                            {/* <li><a onClick={() => this.show_PopUpReportView(1, ["registrationDate","registrationDate2", "tipoParticipante", "organizacion"])}>1. Participantes con Registro y Encuesta (Numérico)</button></li> */}
                                            <li><button onClick={() => this.setState({ showReporte1: true, selectedReport: 1, selectedReportTitle: 'Participantes con Registro y Encuesta de Inscripción (Numérico)' })}>1. Participantes con Registro y Encuesta de Inscripción (Numérico)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte2: true, selectedReport: 2, selectedReportTitle: 'Participantes sin Encuesta de Inscripción (Numérico)' })}>2. Participantes sin Encuesta de Inscripción (Numérico)</button></li>

                                            <li><button onClick={() => this.setState({ showReporte2: true, selectedReport: 3, selectedReportTitle: 'Participantes sin Encuesta de Inscripción (Desglosado)' })}>3. Participantes sin Encuesta de Inscripción (Desglosado)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte4: true, selectedReport: 4, selectedReportTitle: 'Cantidad de Formularios por Tipo de Participante (Numérico)' })}>4. Cantidad de Formularios por Tipo de Participante (Numérico)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte4_1: true, selectedReport: 4.1, selectedReportTitle: '4.1 Cantidad de Formularios por Tipo de Participante (Desglosado) ' })}>4.1. Cantidad de Formularios por Tipo de Participante (Desglosado)</button></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mainBox reportsBox marginBottom80">
                                <img alt="icon report 4" src={iconReport4} />
                                <h4>Resultados de Encuestas</h4>
                                <Row>
                                    <Col lg="12">
                                        <ul>
                                            <li><button onClick={() => this.setState({ showReporte5: true, selectedReport: 5, selectedReportTitle: 'Resultados de Encuestas (Numérico)' })}>5. Resultados de Encuestas (Numérico)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte5: true, selectedReport: 6, selectedReportTitle: 'Resultados de Encuestas (Desglosado)' })}>6. Resultados de Encuestas (Desglosado)</button></li>
                                        </ul>
                                    </Col>
                                </Row>

                            </div>
                            <SecuredComponent roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                <div className="mainBox reportsBox marginBottom80">
                                    <img alt="icon report 5" src={iconReport5} />
                                    <h4>Exportación</h4>
                                    <Row>
                                        <Col lg="12">
                                            <ul>
                                                <li><button type='button' onClick={() => this.setState({ showModalRegistro: true })}>1. Formulario de Registro</button></li>
                                                <li><button type='button' onClick={() => this.setState({ showModalEncuestas: true })}>2. Exportación de Encuesta</button></li>
                                                <li><button type='button' onClick={() => this.setState({ showModalEncuestas2: true })}>3. Exportación de dos encuestas</button></li>

                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </SecuredComponent>
                        </Col>
                        <Col lg="6">

                            {/* <div className="mainBox reportsBox marginBottom80">
                        <img src={iconReport2}/>
                        <h4>Medición de Indicadores</h4>
                        <Row>
                            <Col lg="12">
                                <ul>
                                    <li><button onClick={() => this.show_PopUpReportView(12, generateForm("tipoParticipanteAll", "programa"), "Total de Participación por Organización, Programa y Tipo de Actividad (Numérico)")}>12. Total de Participación por Organización, Programa y Tipo de Actividad (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(13, generateForm("tipoParticipanteDirecto"), "Colocación laboral basada en Encuesta Laboral (Numérico)")}>13. Colocación laboral basada en Encuesta Laboral (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(14, generateForm("tipoParticipanteDirecto", ["trabajoEs", "14"]), "Colocación laboral basado en Encuesta Laboral (Desglosado)")}>14. Colocación laboral basado en Encuesta Laboral (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(15, generateForm("tipoParticipanteDirecto", "trabajoEs"), "Colocación laboral basado en Encuesta Laboral (Numérico)")}>15. Jóvenes no colocados a nivel laboral basado en Encuesta Laboral (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(16, generateForm("tipoParticipanteDirecto", "trabajoEs"), "Colocación laboral basado en Encuesta Laboral (Desglosado)")}>16. Jóvenes no colocados a nivel laboral basado en Encuesta Laboral (Desglosado)</button></li>
                          
                                    <li><button onClick={() => this.show_PopUpReportView(17, generateForm("tipoParticipanteDirecto"), "Jóvenes Envueltos en Crimen y Violencia (Numérico)")}>17. Jóvenes Envueltos en Crimen y Violencia (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(18, generateForm("tipoParticipanteDirecto"), "Jóvenes Envueltos en Crimen y Violencia (Desglosado)")}>18. Jóvenes Envueltos en Crimen y Violencia (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(19, generateForm("tipoParticipanteDirecto"), "Jóvenes sin Acta de Nacimiento (Numérico)")}>19. Jóvenes sin Acta de Nacimiento (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(20, generateForm("tipoParticipanteDirecto"), "Jóvenes sin Acta de Nacimiento (Desglosado)")}>20. Jóvenes sin Acta de Nacimiento (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(21, generateForm("tipoParticipanteDirecto"), "Jóvenes NiNi (Numérico)")}>21. Jóvenes NiNi (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(22, generateForm("tipoParticipanteDirecto"), "Jóvenes NiNi (Desglosado)")}>22. Jóvenes NiNi (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(23, generateForm("tipoParticipanteDirecto"), "Uso de anticonceptivos modernos (Numérico)")}>23. Uso de anticonceptivos modernos (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(24, generateForm("tipoParticipanteDirecto"), "Uso de anticonceptivos modernos (Desglosado)")}>24. Uso de anticonceptivos modernos (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(25, generateForm("tipoParticipanteDirecto"), "Discapacidad (Numérico)")}>25. Discapacidad (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(26, generateForm("tipoParticipanteDirecto", ["trabajoEs", "26"]), "Discapacidad (Desglosado)")}>26. Discapacidad (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(27, generateForm("tipoParticipanteDirecto"), "Violencia de Género (Numérico)")}>27. Violencia de Género (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(28, generateForm("tipoParticipanteDirecto", ["trabajoEs", "28"]), "Violencia de Género (Desglosado)")}>28. Violencia de Género (Desglosado)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(29, generateForm("tipoParticipanteDirecto"), "Orientación Sexual (Numérico)")}>29. Orientación Sexual (Numérico)</button></li>
                                    <li><button onClick={() => this.show_PopUpReportView(30, generateForm("tipoParticipanteDirecto", ["trabajoEs", "30"]), "Orientación Sexual (Desglosado)")}>30. Orientación Sexual (Desglosado)</button></li>
                                </ul>
                            </Col>
                        </Row>
                    </div> */}

                            <div className="mainBox reportsBox marginBottom80">
                                <img alt="icon report 5" src={iconReport5} />
                                <h4>Avances por Programa</h4>
                                <Row>
                                    <Col lg="12">
                                        <ul>
                                            <li><button onClick={() => this.setState({ showReporte7: true, selectedReport: 7, selectedReportTitle: 'Servicios por Programa' })}>7. Servicios por Programa</button></li>
                                            <li><button onClick={() => this.setState({ showReporte3: true, selectedReport: 8, selectedReportTitle: 'Participantes Únicos por Programas (Numérico)' })}>8. Participantes Únicos por Programas (Numérico)</button></li>

                                            <li><button onClick={() => this.setState({ showReporte3: true, selectedReport: 9, selectedReportTitle: 'Participantes Únicos por Programas (Desglosado)' })}>9. Participantes Únicos por Programas (Desglosado)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte3: true, selectedReport: 10, selectedReportTitle: 'Participantes Únicos Recibiendo Servicios (Numérico)' })}>10. Participantes Únicos Recibiendo Servicios (Numérico)</button></li>
                                            <li><button onClick={() => this.setState({ showReporte3: true, selectedReport: 11, selectedReportTitle: 'Participantes Únicos Recibiendo Servicios (Desglosado)' })}>11. Participantes Únicos Recibiendo Servicios (Desglosado)</button></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mainBox reportsBox marginBottom80">
                                <img alt="icon report 3" src={iconReport3} />
                                <h4>Encuestas</h4>
                                <ul>
                                    {/* <li><button onClick={() => this.show_PopUpReportView(31, generateForm("tipoParticipanteAll", "programa"), "Total de Actividades de Salida (Numérico)")}>31. Total de Actividades de Salida (Numérico)</button></li>
                            <li><button onClick={() => this.show_PopUpReportView(32, generateForm("tipoParticipanteAll", "programa"), "Listado de Asistentes de Actividades de Salida (Desglosado) ")}>32. Listado de Asistentes de Actividades de Salida (Desglosado)</button></li>
                            <li><button onClick={() => this.show_PopUpReportView(33, generateForm("tipoParticipanteAll", "programa"), "Listado de Beneficiarios que no han terminado un programa (Desglosado)")}>33. Listado de Beneficiarios que no han terminado un programa (Desglosado)</button></li>
                            <li><button onClick={() => this.show_PopUpReportView(34, generateForm("tipoParticipanteAll", "programa"), "Total de Actividades de Salida por Organización y Tipo de Actividad (Numérico)")}>34. Total de Actividades de Salida por Organización y Tipo de Actividad (Numérico)</button></li>
                            <li><button onClick={() => this.show_PopUpReportView(35, generateForm("tipoParticipanteAll", "programa"), "Total de Actividades de Salida por Organización y Tipo de Actividad (Desglosado)")}>35. Total de Actividades de Salida por Organización y Tipo de Actividad (Desglosado)</button></li> */}
                                    <li><button onClick={() => this.setState({ showReporte3: true, selectedReport: 12, selectedReportTitle: 'Listado de Actividades' })}>12. Listado de Actividades</button></li>
                                    <li><button onClick={() => this.setState({ showReporte13: true, selectedReport: 13, selectedReportTitle: 'Participantes con o sin Servicios (Desglosado)' })}>13.  Participantes con o sin Servicios (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showReporteEncuestas: true, selectedReport: 14, selectedReportTitle: 'Capacitados en curso vocacional y sin empleos y/o emprendimiento (Desglosado)' })}>14. Capacitados en curso vocacional y sin empleos y/o emprendimiento (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showReporteEncuestas2: true, selectedReport: 15, selectedReportTitle: 'Servidos sin Encuesta de Inscripción a Participantes (Desglosado)' })}>15. Servidos sin Encuesta de Inscripción a Participantes (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showReporteEncuestas: true, selectedReport: 16, selectedReportTitle: 'Estatus de Participantes (Desglosado)' })}>16. Estatus de Participantes (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showModalBeneficiariosDeSalida: true, selectedReport: 17, selectedReportTitle: 'Total de Beneficiarios de Salida (Numérico)' })}>17. Total de Beneficiarios de Salida (Numérico)</button></li>
                                    <li><button onClick={() => this.setState({ showModalBeneficiariosDeSalida: true, selectedReport: 18, selectedReportTitle: 'Total de Beneficiarios de Salida (Desglosado)' })}>18. Total de Beneficiarios de Salida (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showModalBeneficiariosDeSalida19: true, selectedReport: 19, selectedReportTitle: 'Participantes Recibiendo Servicios (Desglosado)' })}>19. Participantes Recibiendo Servicios (Desglosado)</button></li>
                                    <li><button onClick={() => this.setState({ showModalReporte20: true, selectedReport: 20, selectedReportTitle: 'Colocación Laboral Basado en Encuesta Laboral (Numérico)' })}>20. Colocación laboral basado en Encuesta Laboral (Númerico)</button></li>
                                    <li><button onClick={() => this.setState({ showModalReporte20: true, selectedReport: 21, selectedReportTitle: 'Colocación Laboral Basado en Encuesta Laboral (Desglosado)' })}>21. Colocación Laboral Basado en Encuesta Laboral (Desglosado)</button></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </CSSTransition>
                <Modal
                    size="lg"
                    centered
                    show={popupReportView}
                    onHide={this.hide_PopUpReportView}
                >
                    <Modal.Header closeButton>

                        <Modal.Title>{reportName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                organization_id: auth.user.role.nombre !== ROLE_SYSTEM_ADMIN ? auth.user.organizacion.id : "",
                                fecha_inicio: "",
                                fecha_fin: "",
                                participantType: "",
                                program: "",
                                escalariesgo_formfield: "",
                                escalariesgo_response: "",
                                status: 0,
                                formType: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                if (auth.user.role.nombre === ROLE_SYSTEM_ADMIN) {
                                    if (values.organization_id !== "") {
                                        let organizacion = "";
                                        values.organization_id.map(function (v) {
                                            organizacion = organizacion + v.id + ",";
                                        })
                                        values.organization_id = organizacion;
                                    }
                                } else {
                                    values.organization_id = auth.user.organizacion.id;
                                }
                                if (values.trabajo_es !== undefined && values.trabajo_es !== "") {
                                    let trabajo = "";
                                    values.trabajo_es.map(function (v) {
                                        trabajo = trabajo + v._id + ", ";
                                    })
                                    values.trabajo_es = trabajo;
                                }
                                if (values.program !== undefined && values.program !== "") {
                                    let program = "";
                                    values.program.map(function (v) {
                                        program = program + v._id + ",";
                                    })
                                    values.program = program;
                                } else {
                                    delete values.program;
                                }
                                if (values.participantType === undefined || values.participantType === "") {
                                    delete values.participantType;
                                }
                                if (escalaRiesgoSelected !== []) {
                                    escalaRiesgoSelected.map(function (er, index) {

                                        if (er.dependence_type === "621e56d609a37e4ee83e23a2") {
                                            switch (er.operator) {
                                                case "0":
                                                case "1":
                                                case "2":
                                                    values["field_" + er.form_field_id] = er.answer + "," + er.operator;
                                                    break;
                                                case "3":
                                                    // fieldresponses.filter(option => option.form_field_id == values.escalariesgo_formfield )
                                                    let responses = "";
                                                    fieldresponses.map(function (answer) {
                                                        if (er.form_field_id === answer.form_field_id && er.answer !== answer._id) {
                                                            responses = responses + answer._id + ",";
                                                        }
                                                    })
                                                    values["field_" + er.form_field_id] = responses + "," + er.operator;
                                                    break;
                                                case "4":
                                                    let responsesAll = "";
                                                    fieldresponses.map(function (answer) {
                                                        if (er.form_field_id === answer.form_field_id) {
                                                            responsesAll = responsesAll + answer._id + ",";
                                                        }
                                                    })
                                                    values["field_" + er.form_field_id] = responsesAll + "," + er.operator;
                                                    break;
                                                default:
                                                    break;
                                            }
                                        } else {
                                            values["field_" + er.form_field_id] = er.answer + "," + er.operator;
                                        }

                                        // values["field_" + er.form_field_id] = er.answer;
                                    })
                                    delete values.escalariesgo_formfield;
                                    delete values.escalariesgo_response;
                                    delete values.escalariesgo_operator;
                                }

                                if (this.state.export) {
                                    values.id = reportId;
                                    req.get(API + "exports", { params: values }).then((res) => {
                                        fileDownload(res.data, reportName + '.csv');
                                    });
                                } else {
                                    localStorage.setItem("forResults", JSON.stringify(values));
                                    this.props.history.push("/reportes/" + this.state.reportId);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        {this.state.options !== undefined ?
                                            this.state.options.map((s) => (
                                                <div>

                                                    {s === "registrationDate" ? (
                                                        <FS label="Fecha de Creación Inicial" name="fecha_inicio"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <DatePickerField name="fecha_inicio" />
                                                        </FS>
                                                    ) : null}
                                                    {s === "registrationDate2" ? (
                                                        <FS
                                                            label="Fecha de Creación Final"
                                                            name="fecha_fin"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <DatePickerField name="fecha_fin" />
                                                        </FS>
                                                    ) : null}
                                                    {s === "organizacion" && auth.user.role.nombre === ROLE_SYSTEM_ADMIN ? (
                                                        <FS
                                                            label="Organización"
                                                            name="organization_id"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Select
                                                                isMulti
                                                                name={"organization_id"}
                                                                getOptionLabel={(option) => option.nombre}
                                                                getOptionValue={(option) => option.id}
                                                                options={[{ nombre: "Seleccionar todos", id: "*" }, ...organizaciones]}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => {
                                                                    let value = e;
                                                                    if (value.length !== 0) {
                                                                        if (e[0].id === '*') {
                                                                            value = organizaciones;
                                                                        }
                                                                    }
                                                                    setFieldValue("organization_id", value);
                                                                }}
                                                                value={values.organization_id}
                                                            />
                                                        </FS>
                                                    ) : null}
                                                    {s === "tipoParticipante" ? (
                                                        <FS label="Tipo de Participante" name="participantType"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Field name="participantType" as="select">
                                                                <option value="2"> Todos </option>
                                                                <option value="0"> Directo </option>
                                                                <option value="1"> Indirecto </option>
                                                            </Field>
                                                        </FS>
                                                    ) : null}
                                                    {/* {s == "tipoParticipanteDirecto" ? (
                        <Field  name="participantType"  as="select">
                        <option value="null"> Selecciona </option>
                        <option value="0"> Directo </option>
                        </Field>
                    ) : null} */}
                                                    {s[0] === "trabajoEs" ? (
                                                        <FS
                                                            label={trabajoEs[s[1]].title}
                                                            name="trabajo_es"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Select
                                                                isMulti
                                                                name="trabajo_es"
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option._id}
                                                                options={[{ name: "Seleccionar todos", _id: "*" }, ...trabajoEs[s[1]].options]}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => {
                                                                    let value = e;
                                                                    if (value.length !== 0) {
                                                                        if (e[0]._id === '*') {
                                                                            value = trabajoEs[s[1]].options;
                                                                        }
                                                                    }
                                                                    setFieldValue("trabajo_es", value);
                                                                }}
                                                                value={values.trabajo_es}
                                                            />
                                                        </FS>
                                                    ) : null}
                                                    {s === "programa" ? (
                                                        <FS
                                                            label="Programas"
                                                            name="program"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Select
                                                                isMulti
                                                                name={"program"}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option._id}
                                                                options={[{ name: "Seleccionar todos", _id: "*" }, ...programstype]}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => {
                                                                    let value = e;
                                                                    if (value.length !== 0) {
                                                                        if (e[0]._id === '*') {
                                                                            value = programstype;
                                                                        }
                                                                    }
                                                                    setFieldValue("program", value);
                                                                }}
                                                                value={values.program}
                                                            />
                                                        </FS>
                                                    ) : null}
                                                    {s === "actividades" ? (
                                                        <FS
                                                            label="Actividades"
                                                            name="activity_id"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Select
                                                                isMulti
                                                                name={"activity_id"}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option._id}
                                                                options={[{ name: "Seleccionar todos", _id: "*" }, ...actividades]}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={e => {
                                                                    let value = e;
                                                                    if (value.length !== 0) {
                                                                        if (e[0]._id === '*') {
                                                                            value = actividades;
                                                                        }
                                                                    }
                                                                    setFieldValue("activity_id", value);
                                                                }}
                                                                value={values.activity_id}
                                                            />
                                                        </FS>
                                                    ) : null}
                                                    {s === "tipoencuesta" ? (
                                                        <FS label="Tipo de Encuesta" name="formType"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Field name="formType" as="select">
                                                                <option value="null"> Selecciona </option>
                                                                <option value="EE"> Encuenta de Inscripción a Participantes </option>
                                                                <option value="ES"> Encuesta de Salida</option>
                                                                <option value="ET"> Encuesta Laboral </option>
                                                                <option value="E"> Evaluación </option>
                                                                {additionalForms.map((p) => (
                                                                    <option value={p.value}>{findFormName(p.value, forms)}</option>
                                                                ))}
                                                            </Field>
                                                        </FS>
                                                    ) : null}
                                                    {s === "cierre" ? (
                                                        <FS label="Participación" name="status"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Field name="status" as="select">
                                                                <option value="0"> Solo participó </option>
                                                                <option value="1"> Completó programa </option>
                                                            </Field>
                                                        </FS>
                                                    ) : null}
                                                    {s === "cierre2" ? (
                                                        <FS label="Participación" name="status"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Field name="status" as="select">
                                                                <option value="0"> Solo participó </option>
                                                                <option value="1"> Completó programa </option>
                                                                <option value="2"> No completó programa </option>
                                                            </Field>
                                                        </FS>
                                                    ) : null}
                                                    {s === "cierre3" ? (
                                                        <FS label="Participación" name="status"
                                                            errors={errors}
                                                            col={[12]}
                                                        >
                                                            <Field name="status" as="select">
                                                                <option value="0"> Con servicio </option>
                                                                <option value="1"> Sin Servicio </option>
                                                            </Field>
                                                        </FS>
                                                    ) : null}
                                                    {s === "escalariesgo" ? (
                                                        <>
                                                            <strong>Preguntas</strong>
                                                            <FS
                                                                label="Formulario *"
                                                                name="form_id"
                                                                errors={errors}

                                                                col={[12]}
                                                            >
                                                                <Field name="form_id" as="select">
                                                                    <option value="null"> Selecciona </option>
                                                                    {forms.map((p) => (
                                                                        <option value={p._id}> {p.name}</option>
                                                                    ))}
                                                                </Field>
                                                            </FS>
                                                            <div className="subBox">
                                                                <div className="form-group">
                                                                    <Row>
                                                                        <FS
                                                                            label="Pregunta"
                                                                            name="escalariesgo_formfield"
                                                                            errors={errors}
                                                                            col={[6]}
                                                                        >
                                                                            <Field name="escalariesgo_formfield"
                                                                                onChange={(e) => this.selectDependence_formfield(e.target.value, setFieldValue)}
                                                                                ref={this.question} as="select">
                                                                                <option value="null"> Selecciona </option>
                                                                                {formfields && formfields.length > 0
                                                                                    ? formfields.sort(function (a, b) {
                                                                                        return a.title.split('.')[0] - b.title.split('.')[0];
                                                                                    }).map((type, i) => (
                                                                                        type.form_id === values.form_id ? (
                                                                                            <option key={type._id} value={type._id}>
                                                                                                {" "}
                                                                                                {type.title}{" "}
                                                                                            </option>
                                                                                        ) : null
                                                                                    )
                                                                                    )
                                                                                    : null}
                                                                            </Field>
                                                                        </FS>
                                                                        <FS
                                                                            label="Operador"
                                                                            name="escalariesgo_operator"
                                                                            errors={errors}
                                                                            col={[6]}
                                                                        >
                                                                            <Field ref={this.operator} name="escalariesgo_operator" as="select">
                                                                                <option value="null"> Selecciona </option>
                                                                                <option value="0"> Sea Igual a</option>
                                                                                <option value="1">
                                                                                    {" "}
                                                                                    Sea Menor o igual a{" "}
                                                                                </option>
                                                                                <option value="2">
                                                                                    {" "}
                                                                                    Sea Mayor o igual a{" "}
                                                                                </option>
                                                                                <option value="3"> Sea Diferente a </option>
                                                                                <option value="4"> Todas las respuestas </option>
                                                                            </Field>
                                                                        </FS>

                                                                        {values.escalariesgo_operator !== "4" ? (
                                                                            <FS
                                                                                label="Respuesta"
                                                                                name="escalariesgo_response"
                                                                                errors={errors}
                                                                                col={[6]}
                                                                            >
                                                                                {dependence_type === "621e56e0ef6d6654e9371c82" ? (
                                                                                    <Select
                                                                                        isMulti
                                                                                        name="escalariesgo_response"
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionValue={(option) => option._id}
                                                                                        options={[{ name: "Seleccionar todos", _id: "*" }, ...fieldresponses.sort(function (a, b) {
                                                                                            if (a.name < b.name) { return -1; }
                                                                                        }).filter(option => option.form_field_id === values.escalariesgo_formfield)]}
                                                                                        className="basic-multi-select"
                                                                                        classNamePrefix="select"
                                                                                        onChange={e => {
                                                                                            let value = e;
                                                                                            if (value.length !== 0) {
                                                                                                if (e[0]._id === '*') {
                                                                                                    value = fieldresponses.filter(option => option.form_field_id === values.escalariesgo_formfield);
                                                                                                }
                                                                                            }
                                                                                            setFieldValue("escalariesgo_response", value);
                                                                                        }}
                                                                                    />

                                                                                ) : null}

                                                                                {dependence_type === "621e56d609a37e4ee83e23a2" ? (
                                                                                    <Field name="escalariesgo_response" as="select">
                                                                                        <option value="null"> Selecciona </option>
                                                                                        {fieldresponses && fieldresponses.length > 0
                                                                                            ?
                                                                                            fieldresponses.sort(function (a, b) {
                                                                                                if (a.name < b.name) { return -1; }
                                                                                            }).map((type, i) =>
                                                                                                type.form_field_id === values.escalariesgo_formfield ? (
                                                                                                    <option key={type._id} value={type._id}>
                                                                                                        {" "}
                                                                                                        {type.name}{" "}
                                                                                                    </option>
                                                                                                ) : null
                                                                                            )
                                                                                            : null}
                                                                                    </Field>
                                                                                ) : null}

                                                                                {/* Si o No */}
                                                                                {dependence_type === "621e574f99bc356e77757d93" ? (
                                                                                    <Field ref={this.answerDependence} name="escalariesgo_response" as="select">
                                                                                        <option value="null"> Selecciona </option>
                                                                                        <option value="1"> Si </option>
                                                                                        <option value="0"> No </option>
                                                                                    </Field>
                                                                                ) : null}

                                                                                {/* Verdadero o Falso */}
                                                                                {dependence_type === "621e5757148269271b6b0512" ? (
                                                                                    <Field ref={this.answerDependence} name="escalariesgo_response" as="select">
                                                                                        <option value="null"> Selecciona </option>
                                                                                        <option value="1"> Verdadero </option>
                                                                                        <option value="0"> Falso </option>
                                                                                    </Field>
                                                                                ) : null}

                                                                                {/* Otros */}
                                                                                {dependence_type !== "621e56e0ef6d6654e9371c82" &&
                                                                                    dependence_type !== "621e56d609a37e4ee83e23a2" &&
                                                                                    dependence_type !== "621e574f99bc356e77757d93" &&
                                                                                    dependence_type !== "621e5757148269271b6b0512" ? (
                                                                                    <Field ref={this.answerDependence} type="text" name="escalariesgo_response" />
                                                                                ) : null}
                                                                            </FS>
                                                                        ) : (
                                                                            <Field ref={this.answerDependence} value="null" type="text" hidden name="dependence_response" />
                                                                        )}
                                                                        <Col lg={4}>
                                                                            <Button
                                                                                variant="primary "
                                                                                className="secondaryButton"
                                                                                onClick={() => this.addDependence(values, dependence_type)}
                                                                            >
                                                                                Añadir
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>

                                                            <MaterialTable
                                                                title=""
                                                                columns={[
                                                                    {
                                                                        title: 'Pregunta', field: 'form_field_id',
                                                                        render: (rowData) =>
                                                                            getName(rowData.form_field_id, formfields)
                                                                    },

                                                                    {
                                                                        title: 'Operador', field: 'operator',
                                                                        render: (rowData) => (
                                                                            returnOperator(rowData.operator)
                                                                        )
                                                                    },
                                                                    {
                                                                        title: 'Respuesta', field: 'answer', render: (rowData) => (
                                                                            returnAnswer(rowData, fieldresponses, formfields)
                                                                        )
                                                                    },
                                                                    {
                                                                        title: "Acciones",
                                                                        searchable: true,
                                                                        cellStyle: {
                                                                            minWidth: 200,
                                                                            maxWidth: 200
                                                                        },
                                                                        field: "actions",
                                                                        render: (rowData) => (
                                                                            <IconButton

                                                                                onClick={() => this.remove_question(rowData)}
                                                                            >
                                                                                <DeleteOutlineIcon />
                                                                            </IconButton>

                                                                        )
                                                                    }
                                                                ]}
                                                                localization={{
                                                                    toolbar: {
                                                                        searchTooltip: 'Buscar',
                                                                        searchPlaceholder: 'Buscar'
                                                                    },
                                                                    pagination: {
                                                                        previousTooltip: 'Página anterior',
                                                                        nextTooltip: 'Página siguiente',
                                                                        firstTooltip: 'Primera página',
                                                                        lastTooltip: 'Última página'
                                                                    },
                                                                    body: {
                                                                        emptyDataSourceMessage: 'No hay registros que mostrar',
                                                                    }
                                                                }}
                                                                data={escalaRiesgoSelected}
                                                                options={{
                                                                    search: false,
                                                                }}
                                                            />
                                                        </>
                                                    ) : null}
                                                </div>
                                            )
                                            ) : null}
                                        <Col lg="12">
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                variant="primary "
                                            >
                                                {isSubmitting ? "Creando..." : "Buscar"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            )}
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <ModalRegistro show={this.state.showModalRegistro} onHide={() => { this.setState({ showModalRegistro: false }) }} />
                <ModalExportableEncuestas show={this.state.showModalEncuestas} onHide={() => { this.setState({ showModalEncuestas: false }) }} />
                <ModalExportableEncuestas2 show={this.state.showModalEncuestas2} onHide={() => { this.setState({ showModalEncuestas2: false }) }} />
                <ModalAdministracion show={this.state.showReporte1} onHide={() => this.setState({ showReporte1: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalAdministracion2 show={this.state.showReporte2} onHide={() => this.setState({ showReporte2: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalReporte4 show={this.state.showReporte4} onHide={() => this.setState({ showReporte4: false })} title={this.state.selectedReportTitle} formularios={this.state.forms} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalReporte4_1 show={this.state.showReporte4_1} onHide={() => this.setState({ showReporte4_1: false })} title={this.state.selectedReportTitle} formularios={this.state.forms} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalResultadosEncuestas show={this.state.showReporte5} onHide={() => this.setState({ showReporte5: false })} title={this.state.selectedReportTitle} formularios={this.state.forms} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalReporte20 show={this.state.showModalReporte20} onHide={() => this.setState({ showModalReporte20: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalAvancesPorPrograma tipoActividades={this.state.tipoActividades} show={this.state.showReporte3} onHide={() => this.setState({ showReporte3: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} programas={this.state.programas} reportNo={this.state.selectedReport} showFinalizado={true} />
                <ModalReporte7 tipoActividades={this.state.tipoActividades} show={this.state.showReporte7} onHide={() => this.setState({ showReporte7: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} programas={this.state.programas} reportNo={this.state.selectedReport} />
                <ModalReporte13 show={this.state.showReporte13} onHide={() => this.setState({ showReporte13: false })} title={this.state.selectedReportTitle} tiposFormularios={this.state.formTypes} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalReporteEncuestas show={this.state.showReporteEncuestas} onHide={() => this.setState({ showReporteEncuestas: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalReporteEncuestas2 show={this.state.showReporteEncuestas2} onHide={() => this.setState({ showReporteEncuestas2: false })} title={this.state.selectedReportTitle} organizaciones={this.state.organizaciones} reportNo={this.state.selectedReport} />
                <ModalBeneficiariosDeSalida tipoActividades={this.state.tipoActividades} show={this.state.showModalBeneficiariosDeSalida} onHide={() => this.setState({ showModalBeneficiariosDeSalida: false })} organizaciones={this.state.organizaciones} title={this.state.selectedReportTitle} reportNo={this.state.selectedReport} />
                <ModalBeneficiariosDeSalida19 tipoActividades={this.state.tipoActividades} show={this.state.showModalBeneficiariosDeSalida19} onHide={() => this.setState({ showModalBeneficiariosDeSalida19: false })} organizaciones={this.state.organizaciones} title={this.state.selectedReportTitle} reportNo={this.state.selectedReport} />
            </Container>
        );
    }
}
function generateForm(tipoParticipante, other, another) {
    let form = ["registrationDate", "registrationDate2", "organizacion"];
    switch (tipoParticipante) {
        case "tipoParticipanteAll":
            form.push("tipoParticipante")
            break;
        case "indirecto":
            form.push("tipoParticipanteIndirecto")
            break;
        case "tipoParticipanteDirecto":
            form.push("tipoParticipanteDirecto")
            break;

        default:
            break;
    }
    if (other !== undefined) {
        form.push(other);
    }
    if (another !== undefined) {
        form.push(another);
    }
    return form;
}

function returnAnswer(r, data, formfieldsAll) {
    if (r.dependence_type === undefined) {
        for (var i = 0, len = formfieldsAll.length; i < len; i++) {
            if (formfieldsAll[i]._id === r.form_field_id) {
                r.dependence_type = formfieldsAll[i].questio_type_id
            }
        }
    }
    if (r.dependence_type === "621e56e0ef6d6654e9371c82") {
        return r.answerName;
    }
    if (r.dependence_type === "621e56d609a37e4ee83e23a2") {
        return getName(r.answer, data);
    }
    if (r.dependence_type === "621e574f99bc356e77757d93") {
        return r.answer === "1" ? "Si" : "No";
    }
    if (r.dependence_type === "621e5757148269271b6b0512") {
        return r.answer === "1" ? "Verdadero" : "Falso";
    }
    if (r.dependence_type !== "621e56e0ef6d6654e9371c82" &&
        r.dependence_type !== "621e56d609a37e4ee83e23a2" &&
        r.dependence_type !== "621e574f99bc356e77757d93" &&
        r.dependence_type !== "621e5757148269271b6b0512") {
        return r.answer;
    }
}
function findFormName(id, forms) {
    let name = "";
    forms.filter((n) => {
        if (n._id === id) {
            name = n.name;
        }
    });
    return name;
}
function returnOperator(value) {
    switch (value) {
        case '0':
            return "Sea Igual a";
            break;
        case '1':
            return "Sea Menor o igual a";
            break;
        case '2':
            return "Sea Mayor o igual a";
            break;
        case '3':
            return "Sea Diferente a";
            break;
        case '4':
            return "Todas las respuestas";
            break;
        default:
            break;
    }
}
export default withRouter(Reports);