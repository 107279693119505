import MaterialTable from "material-table";
import { Link } from 'react-router-dom'
import { ROLE_SYSTEM_ADMIN } from "../utils/roles";
import { changeDateFormat, checkStatus } from "../components/functions";
import { mdiConsoleLine } from '@mdi/js';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { useState } from "react";
import AuthService from '../services/auth.service';

export default function BeneficiariesListTable({ onMoreClick, data, editRow, desactivateRow, activateRow, showAudit }) {
    const auth = AuthService.getCurrentUser();
    const isAdmin = AuthService.isAdmin();
    
    const filteredData = isAdmin ? data : data.filter((x) => x.organizacion.id === auth?.user?.organizacion?.id);
    const [page, setPage] = useState(0);
    return (
        <MaterialTable
            page={page}
            onChangePage={setPage}
            title=''
            columns={[
                {
                    title: 'ID',
                    field: 'codigo',
                    render: (rowData) => (
                        <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                            {rowData.codigo}
                        </Link>
                    ),
                },
                {
                    title: 'Nombre',
                    field: 'nombres',
                    render: (rowData) => (
                        <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                            {rowData.nombres}
                        </Link>
                    ),
                    customFilterAndSearch: (term, rowData) =>
                        (
                            (rowData.nombres !== null ? rowData.nombres.toLowerCase() : '') +
                            ' ' +
                            (rowData.apellidos !== null
                                ? rowData.apellidos.toLowerCase()
                                : '')
                        ).indexOf(term !== null ? term.toLowerCase() : '') !== -1,
                },
                {
                    title: 'Apellido',
                    field: 'apellidos',
                    render: (rowData) => (
                        <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                            {rowData.apellidos}
                        </Link>
                    ),
                },
                {
                    title: 'Organización',
                    field: 'organizacion',
                    hidden:
                        auth?.user?.role?.nombre !== ROLE_SYSTEM_ADMIN
                            ? true
                            : false,
                    render: (rowData) => rowData?.organizacion?.nombre,
                    customFilterAndSearch: (term, rowData) =>
                        rowData.organizacion.nombre
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) !== -1,
                },
                {
                    title: 'Fecha de Registro',
                    field: 'registro',
                    render: (rowData) => changeDateFormat(rowData.registro),
                },
                {
                    title: 'Fecha de Nacimiento',
                    field: 'fecha_nacimiento',
                    render: (rowData) =>
                        changeDateFormat(rowData.fecha_nacimiento),
                },
                {
                    title: 'Sexo',
                    field: 'genero',
                    render: (rowData) =>
                        rowData.genero.charAt(0).toUpperCase() +
                        rowData.genero.slice(1),
                },
                {
                    title: 'Tipo de Participante',
                    field: 'tipo.nombre',
                },
                {
                    title: 'Estatus',
                    field: 'activo',
                    render: (rowData) => checkStatus(rowData.activo),
                },
                { title: '', field: 'entryForm', hidden: 'false' },
                { title: '', field: 'entryFormAnswers', hidden: 'false' },
                { title: '', field: 'exitForm', hidden: 'false' },
                { title: '', field: 'exitFormAnswers', hidden: 'false' },
                { title: '', field: 'laboralForm', hidden: 'false' },
                { title: '', field: 'laboralFormAnswers', hidden: 'false' },

                {
                    title: 'Acciones',
                    searchable: true,
                    cellStyle: {
                        minWidth: 250,
                        maxWidth: 250,
                    },
                    field: 'actions',
                    render: (rowData) => (
                        <>
                            {rowData.activo ? (
                                <>
                                    <IconButton
                                        onClick={() => onMoreClick(rowData)}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>

                                    <IconButton onClick={() => editRow(rowData)}>
                                        <ModeEditOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => desactivateRow(rowData)}
                                    >
                                        <DoNotDisturbOnOutlinedIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton onClick={() => activateRow(rowData)}>
                                    <CheckCircleOutlinedIcon />
                                </IconButton>
                            )}
                            <IconButton onClick={() => showAudit(rowData.id)}>
                                <HistoryIcon />
                            </IconButton>
                        </>
                    ),
                },
            ]}
            localization={{
                header: { actions: '' },
                toolbar: {
                    searchTooltip: 'Buscar',
                    searchPlaceholder: 'Buscar',
                },
                pagination: {
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Página siguiente',
                    firstTooltip: 'Primera página',
                    lastTooltip: 'Última página',
                },
                body: {
                    emptyDataSourceMessage: 'No hay registros que mostrar',
                },
            }}
            data={filteredData} 
            options={{
                search: true,
                selection: false,
                searchFieldAlignment: 'right',
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                draggable: false,
            }}
        />
    )
}